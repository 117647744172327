<template>

	
	<div class="cont" id="p10">
		<div class="page-title">{{ program.name }}</div>
		<div class="c-list">
			<div class="c01">
				<div class="s01">
					<div class="s-item">
						<div class="s-header">
							<div class="s-title">결제정보</div>
						</div>
						<div class="s-body">
							<div class="s-content w100">
								<div class="si-title">상품명</div>
								<div class="si-desc setting">
									<input type="text" placeholder="상품명을 입력하세요"
										:rules="[rules.max(item, 'goodsName', 150)]"
										v-model="item.goodsName" />
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">판매금액</div>
								<div class="si-desc setting">
									<input type="number" placeholder="판매금액을 입력하세요"
										:rules="[rules.max(item, 'totAmt', 10)]"
										v-model="item.totAmt" 
										/>
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">연락처</div>
								<div class="si-desc setting">
									<input type="number" placeholder="구매자 연락처를 -없이 숫자만 입력하세요"
										:rules="[rules.max(item, 'buyerTel', 12)]"
										v-model="item.buyerTel" 
										/>
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">부가세</div>
								<div class="si-desc setting">
									<v-btn-toggle 
										v-model="item.interestFree"
									>
										<v-btn
											block
											small
											value="00"
											:class="item.interestFree == '00' ? 'success2' : 'gray'"
										>
											과세
										</v-btn>

										<v-btn
											block
											small
											value="01"
											:class="item.interestFree == '01' ? 'success2' : 'gray'"
										>
											면세
										</v-btn>
									</v-btn-toggle>
								</div>
							</div>
						</div>
					</div>
					
					
					<div class="s-item">
						<div class="s-header">
							<div class="s-title">결제카드정보</div>
						</div>
						<div class="s-body">
							<div class="s-content w100">
								<div class="si-title">부가세</div>
								<div class="si-desc setting">
									<v-btn-toggle 
										v-model="item.cardCate"
									>
										<v-btn
											block
											small
											value="00"
											:class="item.cardCate == '00' ? 'success2' : 'gray'"
										>
											개인카드
										</v-btn>

										<v-btn
											block
											small
											value="01"
											:class="item.cardCate == '01' ? 'success2' : 'gray'"
										>
											법인카드
										</v-btn>
									</v-btn-toggle>
								</div>
							</div>
							<div class="s-content w100">
								<div class="si-title">카드번호</div>
								<div class="si-desc setting">
									<input type="number" placeholder=""
										v-model="cardNumber[0]" 
										:rules="[rules.max(cardNumber, '0', 4)]"
										class="mr-10"
									/>
									<input type="password" placeholder=""
										v-model="cardNumber[1]" 
										:rules="[rules.max(cardNumber, '1', 4)]"
										class="mr-10"
									/>
									<input type="password" placeholder=""
										v-model="cardNumber[2]" 
										:rules="[rules.max(cardNumber, '2', 4)]"
										class="mr-10"
									/>
									<input type="number" placeholder=""
										v-model="cardNumber[3]" 
										:rules="[rules.max(cardNumber, '3', 4)]"
									/>
								</div>
							</div>
							<div class="s-content w50">
								<div class="si-title">유효기간</div>
								<div class="si-desc setting">
									<input type="number" placeholder="MM"
										v-model="item.expireMm" 
										:rules="[rules.max(item, 'expireMm', 2)]"
										class="mr-10"
									/>
									<input type="password" placeholder="YY"
										v-model="item.expireYy" 
										:rules="[rules.max(item, 'expireYy', 2)]"
									/>
								</div>
							</div>
							<div class="s-content w50">
								<div class="si-title">비밀번호</div>
								<div class="si-desc setting">
									<input type="password" placeholder="앞 2자리"
										v-model="item.cardPassword" 
										:rules="[rules.max(item, 'cardPassword', 2)]"
									/>
								</div>
							</div>
							<div class="s-content w50">
								<div class="si-title">본인확인</div>
								<div class="si-desc setting">
									<input type="number" placeholder="주민등록 번호 또는 사업자 번호"
										:rules="[rules.max(item, 'buyerBirth', 10)]"
										v-model="item.buyerBirth" 
										/>
								</div>
							</div>
							<div class="s-content w50">
								<div class="si-desc setting">
									*개인: 주민등록번호 앞 6자리<br/>
									*법인: 사업자번호 10자리
								</div>
							</div>
							<div class="s-content w50">
								<div class="si-title">할부</div>
								<div class="si-desc setting">
									<select
										v-model="item.installment"
										:disabled="installment_disabled"
									>
										<option 
											v-for="(item) in codes.installment"
											:key="item.code"
											:value="item.code"
										>{{ item.codeName }}</option>
									</select>
								</div>
							</div>
							<div class="s-content w50">
								<div class="si-desc setting">
									*5만원 이상 할부 가능 
								</div>
								<a 
									class="float-right"
									@click="dialog = true"
								>할부정보 보기</a>
							</div>
						</div>
					</div>
				</div>
				<div class="s04">
					<a @click="save" class="focus"><div class="btn-submit" :class="{active: isSave}">등록</div></a>
				</div>
			</div>
			
			<div class="ml-10 mt-10" style="padding: 10px !important;">
				<v-img src="@/assets/img/interest/web202101.jpg"></v-img>
			</div>
		</div>
	</div>
</template>

<script>
	
	import { Axios } from '@/resources/axios/axios'
	import { rules } from '@/resources/rules/rules'
	
	export default{
		name: "PaymentCard"
		,props: ['user', 'codes']
		,components: { }
		,data: function(){
			return {
				program: {
					name: this.$language.menu[3].list[0].name
				}
				,item: {
					buyerBirth: ''
					,buyerTel: ''
					,cardCate: '00'
					,cardNo: ''
					,cardPassword: ''
					,expireMm: ''
					,expireYy: ''
					,goodsName: ''
					,installment: '00'
					,interestFree: '00'
					,payType: 'card'
					,productType: ''
					,taxFlag: '00'
					,totAmt: ''
				}
				,cardNumber: ['','','','']
				,rules: rules
				,isSave: false
				,dialog: true
			}
		}
		,computed: {
			installment_disabled: function(){
				if(this.item.totAmt >= 50000){
					return false
				}else{
					this.$set(this.item, 'installment', '00')
					this.$set(this.item, 'interestFree', '00')
					return true
				}
			}
		}
		,methods: {
			save: async function(){
				if(this.isSave){
					try{
						const result = await Axios({
							method: 'post'
							,url: '/pay/approval'
							,data: this.item
							,authorize: true
							,paylink: true
						})

						if(result.success){
							this.$emit('setNotify', { type: 'success', message: '결제가 등록되었습니다'})
							this.$router.push({ name: 'PaymentsHistory' })
						}else{
							this.$emit('setNotify', { type: 'error', message: result.message})
						}
					}catch(E){
						console.log(E)
						this.$emit('setNotify', { type: 'error', message: E})
					}
				}
			}
		}
		,created: function(){
		}
		,watch: {
			cardNumber: {
				deep: true
				,handler: function(call){
					this.item.cardNo = call[0] + '' + call[1] + '' + call[2] + '' + call[3]
				}
			}
			,item: {
				deep: true
				,handler: function(call){
					this.isSave = true
					for(const [key, value] of Object.entries(call)){
						if(!value && key != 'productType'){
							console.log(key + ' : ' + value)
							this.isSave = false
							return
						}
					}
				}
			}
		}
	}
</script>























